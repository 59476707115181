import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.jsx";
import SEO from '../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Sobre Roberto Achar" description="Conheça um pouco sobre a trajetória de Roberto Achar" slug="/about" mdxType="SEO" />
    <h1>{`Sobre mim`}</h1>
    <p>{`Meu nome é Roberto Achar, sou Full Stack Web Developer. Nasci em 1981 na cidade de São Paulo e atualmente moro em `}<a parentName="p" {...{
        "href": "https://www.google.com/maps/place/Itupeva,+State+of+S%C3%A3o+Paulo/@-23.1545841,-47.0668176,14z/data=!3m1!4b1!4m5!3m4!1s0x94cf30d56621a3c9:0xd7fe5ed81a7951c0!8m2!3d-23.1550145!4d-47.0524585"
      }}>{`Itupeva`}</a>{`, uma cidade no interior de São Paulo. Sou casado com a Adriana e temos um filho chamado Eduardo.`}</p>
    <p>{`Meu primeiro contato com programação foi em 1997 quando eu cursava Técnico em Processamento de Dados no `}<a parentName="p" {...{
        "href": "https://www.colegiosaojudas.com.br/"
      }}>{`Colégio São Judas Tadeu`}</a>{`. Meu primeiro emprego como desenvolvedor foi em 2003.`}</p>
    <p>{`Larguei meu emprego no banco Itaú em 2017 e tenho trabalhado por conta própria para acompanhar de perto o crescimento do meu filho.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAADX0lEQVQ4yz2SbUyVZRjHnzjP+9t5zgFGY64odKM23WJ9aDNXUkhDXYY1nEETi+qQGnUAmVaeDDy4HeMdlLfidUOwMlbYyZLiRafU8QWsnMGHvju3vvSh7dd9TtaH/+7dz677d/2u+7klRTZQFRNNsdBUG0Nz0MXqOunsKCokFg7RHX6VcGkx6x7KQZY1dN1CVQ0URUcRe9mn4fPJpKX5kFIw1UpF1wRQd1PgdXmP0h9r4M7iLH/fSnBn9ix7ny9E0QxRI+o18x5US0WWVQEVwKSZfs8sFcPFMT0sx+VwqIKp5kauDJ9kaaSdSOVLyKqOaSbrxDkjCRYTim//QhWk/2CmMDOToPt82E+Vkb1xK73vHWDp9KfMn2zh7vQYg4dC4npkbNPEssQZQ9iaSbCYUhOmqoqUHNEy/FimiLg3V5LQSg5S0TzOZGQ/bfvLiVWWMhs9wPcdDThPl2FlZOMKkBXMwlIVLMWXgusCKpkCZlseTjJOEFd0sZ98kdyiMko35VNbtZd4PM7vk+2E6t9H2jeAHJtHrepCqxnG3PgC1gN5WLYwdjykpJlte+KvBlLx+4MEA+mio0nQNsjPf4ya6HF6wmVkPrEFeWcNau0Ies8NlKFV0j5ZRTt+AePDL/CFOpFs2y/MPAEK4HkCFswgIFa/62dtzoNUbN/C0fp3uDrQSNX2zTyS+7BopmNlrcHcsAml4GXs5yqwI58hn5hFchx/ChYQVsm4Qn1NTi6ZWdmsL9jB9ekz/PXdKH/OfU5npI6irduoqT9MpKGJxqYYJduKcXVFSDlkrF2P5Lre/2aeABeX76NxNE55ywS7Xq/m574mWJ7h4tQ40djHDI2M8uvqHyzevM3C9d/48sefiLZ08ewzhfh1HSkJCaZniKdgULD7LbriS5w6l6Bz4jzTrUeZOvQKM0OtdA0Mc6K7h5m5S1y+dpPFa8skbizT3DvMmW9+YDbxC5WhKqRgIIjnuuRteJzIUJwPWvuYGOxn5XQbV5ur6TxSxxvhd4l09NE/MsbXMwt8dWGBuSsJbq+sEhFjh4+1Ee0dZ/TsOaSA3yMz836K9rxNadVB2ts7WJls5e7YEeI9MT4an2dwcoqSN6uJdfdy/mKCS4klvp2/zK0ksDHK5p17eK0uQu2xVv4B0u30tS03iQ8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "No palco do Frontinsampa participando da talk do Matheus Marsiglio.",
          "title": "No palco do Frontinsampa participando da talk do Matheus Marsiglio.",
          "src": "/static/5ecf250938fb2c45ca37d31177d33066/d9199/about.png",
          "srcSet": ["/static/5ecf250938fb2c45ca37d31177d33066/8ff5a/about.png 240w", "/static/5ecf250938fb2c45ca37d31177d33066/e85cb/about.png 480w", "/static/5ecf250938fb2c45ca37d31177d33066/d9199/about.png 960w", "/static/5ecf250938fb2c45ca37d31177d33066/c1b63/about.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Frontinsampa 2019`}</h2>
    <p>{`Fui convidado para participar da Talk do `}<a parentName="p" {...{
        "href": "https://twitter.com/mtmr0x"
      }}>{`Matheus Marsiglio`}</a>{` no `}<a parentName="p" {...{
        "href": "https://www.frontinsampa.com.br/"
      }}>{`Frontinsampa 2019`}</a>{`. Que cara incrível, demos muitas risadas.`}</p>
    <p>{`Agradeço de coração a `}<a parentName="p" {...{
        "href": "https://twitter.com/seescrevekeit"
      }}>{`Keit Oliveira`}</a>{` pelo convite. Esse evento foi sensacional.`}</p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/fKYE09GYTzw?rel=0",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <h2>{`Entrevista no DevNaEstrada`}</h2>
    <p>{`Eu tive o prazer de ser convidado pelo `}<a parentName="p" {...{
        "href": "https://twitter.com/femontanha"
      }}>{`@femontanha`}</a>{` e `}<a parentName="p" {...{
        "href": "https://twitter.com/raymonsanches"
      }}>{`@raymonsanches`}</a>{` para participar do `}<a parentName="p" {...{
        "href": "https://twitter.com/devnaestrada"
      }}>{`DevNaEstrada`}</a>{`, um podcast sobre tecnologias e entrevistas com profissionais da área. Nessa entrevista eu contei um pouco sobre minha trajetória profissional como desenvolvedor.`}</p>
    <p>{`Você pode ouvir minha entrevista no `}<a parentName="p" {...{
        "href": "https://devnaestrada.com.br/2017/10/13/roberto-achar.html"
      }}>{`Site do DevNaEstrada`}</a>{`, no `}<a parentName="p" {...{
        "href": "https://open.spotify.com/episode/0fZTjSCm6VvjaJmUBR7YY6?si=oQlD6GZdSjiPR4sE9HuRVA"
      }}>{`Spotify`}</a>{` ou no `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=O3gB3yuQkAw"
      }}>{`YouTube`}</a>{`.`}</p>
    <p><blockquote parentName="p" {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "pt",
          "dir": "ltr"
        }}>{`Essa semana participei do podcast `}<a parentName="p" {...{
            "href": "https://twitter.com/devnaestrada"
          }}>{`@devnaestrada`}</a>{` com `}<a parentName="p" {...{
            "href": "https://twitter.com/femontanha"
          }}>{`@femontanha`}</a>{` e `}<a parentName="p" {...{
            "href": "https://twitter.com/raymonsanches"
          }}>{`@raymonsanches`}</a>{`. Esses caras são fantásticos. `}<a parentName="p" {...{
            "href": "https://t.co/uNjFsR038J"
          }}>{`https://t.co/uNjFsR038J`}</a></p>{`— Roberto Achar 🐧 (@RobertoAchar) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/RobertoAchar/status/918864444985348096"
        }}>{`October 13, 2017`}</a></blockquote></p>
    <h2>{`Participação no Alura Live`}</h2>
    <p>{`Fui convidado pelo `}<a parentName="p" {...{
        "href": "https://twitter.com/o_gabsferreira"
      }}>{`@o_gabsferreira`}</a>{` para participar do Alura Live, um programa da `}<a parentName="p" {...{
        "href": "https://www.alura.com.br/"
      }}>{`Alura`}</a>{`, uma plataforma brasileira de cursos de tecnologia. Nesse programa eu conversei sobre o tema Segurança da Informação.`}</p>
    <p>{`Você pode assistir minha participação pelo `}<a parentName="p" {...{
        "href": "https://youtu.be/3NK356O1LmU"
      }}>{`YouTube`}</a>{`.`}</p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/3NK356O1LmU?rel=0",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <h2>{`Linha do tempo`}</h2>
    <p>{`Eu fiz um resumo da minha trajetória profissional como desenvolvedor.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`1997`}</strong>{` `}<br />{`
Iniciei o curso Técnico em Processamento de Dados`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`1998`}</strong>{` `}<br />{`
Consegui meu primeiro estágio na Caixa Econômica Federal`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`1999`}</strong>{` `}<br />{`
Concluí o curso Técnico em Processamento de Dados`}<br />{`
Fui contratado pela Rosch, empresa que prestava serviço para a Caixa Econômica Federal`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2000-2002`}</strong>{` `}<br />{`
Trabalhei na empresa `}<a parentName="p" {...{
            "href": "http://www.procimar.com.br/"
          }}>{`Procimar Cine Vídeo`}</a>{`, digitalizando fitas VHS para DVD e disco vinil para CD`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2003-2004`}</strong>{` `}<br />{`
Consegui meu primeiro emprego como Desenvolvedor Júnior na empresa `}<a parentName="p" {...{
            "href": "http://besafer.com.br/"
          }}>{`Besafer Intelligence`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2005-2006`}</strong>{` `}<br />{`
Líder Técnico na `}<a parentName="p" {...{
            "href": "http://www.maua.sp.gov.br/"
          }}>{`Prefeitura de Mauá`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2007-2010`}</strong>{` `}<br />{`
Desenvolvedor Sênior nas empresas DRM Serviços de Consultoria, `}<a parentName="p" {...{
            "href": "http://www.danresa.com.br"
          }}>{`Danresa Security and Network Solutions`}</a>{`, Diretotal, Palco07, Oficina de Design e BSI Tecnologia`}<br />{`
Trabalhei em projetos do UOL, Itaú, Itaú BBA, Sem Parar, Tinta Suvinil, Coca-Cola, Castrol`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2011-2016`}</strong>{` `}<br />{`
Analista de Segurança da Informação no banco `}<a parentName="p" {...{
            "href": "https://www.itau.com.br/"
          }}>{`Itaú`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2017-2019`}</strong>{` `}<br />{`
`}<em parentName="p">{`Home Office`}</em>{` 😎 `}<br />{`
Consultor independente trabalhando em projetos para a área de Segurança da Informação`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      